<template>
  <v-dialog v-model="modal.toggle" max-width="650" persistent>
    <v-card>
      <v-container>
        <v-row>
          <v-col>
              <OrganizzeModalHeader
                :loading="modal.loading"
                :isEdit="modal.isEdit"
                :title="modal.isEdit ? record.description : recordType.name"
                :isDuplicate="modal.isCopy"
                @onClose="closeModal()"
                class="m-title mb-5"
              />

              <v-text-field
                class="mt-2"
                label="Descrição"
                v-model="record.description"
                :error-messages="recordDescriptionErrors"
                :disabled="modal.loading"
                @blur="$v.record.description.$touch()"
                outlined>
              </v-text-field>

              <v-row class="limit-row" >
                <v-col cols="12" sm="4" md="4" lg="4" class="pb-0 pt-0">
                  <v-currency-field
                    label="Valor"
                    v-model="record.value"
                    prefix="R$"
                    :error-messages="recordValueErrors"
                    :disabled="modal.loading"
                    @blur="$v.record.value.$touch()"
                    @input="$v.record.value.$touch()"
                    outlined
                  />
                </v-col>

                <v-col cols="12" sm="4" md="4" lg="4" class="pb-0 pt-0">
                  <v-menu
                    transition="slide-y-transition"
                    :close-on-content-click="true"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dtEmissionLabel"
                        :label="record.type === 'T' ? 'Data da transferência' : 'Data da Emissão'"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="modal.loading"
                        readonly
                        outlined>
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="record.dtEmission"
                      :color="variables.colorPrimary"
                      no-title
                      scrollable>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="4" md="4" lg="4" class="pb-0 pt-0" v-if="record.type !== 'T'">
                  <v-menu
                    transition="slide-y-transition"
                    :close-on-content-click="true"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dtDueLabel"
                        label="Data de Vencimento"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="modal.loading"
                        readonly
                        outlined>
                        <OrganizzeReactionMenu
                          :title="wasPaid ? 'Efetivado' : 'Pendente'"
                          :liked="!wasPaid"
                          @onLike="wasPaid = false"
                          @onUnlike="wasPaid = true"
                          slot="append-outer"
                        />
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="record.dtDue"
                      no-title
                      scrollable
                      :min="record.dtEmission"
                      :color="variables.colorPrimary">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row class="limit-row" v-if="wasPaid && record.type !== 'T'" no-gutters>
                <v-col>
                  <v-menu
                    transition="slide-y-transition"
                    :close-on-content-click="true"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dtPaidLabel"
                        label="Data do pagamento"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="modal.loading"
                        readonly
                        outlined>
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="record.dtPaid"
                      no-title
                      scrollable
                      :min="record.dtEmission"
                      :color="variables.colorPrimary">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col style="margin-left: 15px;">
                  <v-currency-field
                    label="Multa"
                    v-model="record.interestValue"
                    prefix="R$"
                    :disabled="modal.loading"
                    outlined
                  />
                </v-col>

                <v-col style="margin-left: 15px;">
                  <v-currency-field
                    label="Juros"
                    v-model="record.fineValue"
                    prefix="R$"
                    :disabled="modal.loading"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="pb-0 pt-0 d-flex flex-wrap">

                  <template v-if="record.type === 'T'">
                    <v-autocomplete
                      v-model="record.accountIdFrom"
                      item-text="name"
                      item-value="id"
                      append-icon="mdi-magnify"
                      :label="record.type === 'T' ? 'Saiu da conta' : 'Escolha uma conta'"
                      :error-messages="recordAccountIdFromErrors"
                      :items="accountList"
                      :disabled="modal.loading"
                      @blur="$v.record.accountIdFrom.$touch()"
                      clearable
                      outlined
                      filled
                      dense>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <img
                            :src="(
                              (data.item.Institutions || {}).icon
                              || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'
                            )"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>

                    <v-autocomplete
                      v-model="record.accountIdTo"
                      label="Entrou na conta"
                      item-text="name"
                      item-value="id"
                      append-icon="mdi-magnify"
                      style="margin-left: 15px;"
                      :error-messages="recordAccountIdToErrors"
                      :items="accountList"
                      :disabled="modal.loading"
                      @blur="$v.record.accountIdTo.$touch()"
                      clearable
                      outlined
                      filled
                      dense>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <img
                            :src="(
                              (data.item.Institutions || {}).icon
                              || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'
                            )"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </template>

                  <template v-else>
                    <v-autocomplete
                      v-model="record.accountId"
                      item-text="name"
                      item-value="id"
                      append-icon="mdi-magnify"
                      :label="record.type === 'T' ? 'Saiu da conta' : 'Escolha uma conta'"
                      :error-messages="recordAccountIdErrors"
                      :items="accountList"
                      :disabled="modal.loading"
                      @blur="$v.record.accountId.$touch()"
                      clearable
                      outlined
                      filled
                      dense>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <img
                            :src="(
                              (data.item.Institutions || {}).icon
                              || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'
                            )"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </template>                  
                <!-- </v-col> -->

                <!-- <v-col class="pb-0 pt-0" v-if="record.type !== 'T'"> -->
                  <div :class="{ 'col-12':  record.type === 'T'}" class="pl-0 pr-0">
                    <v-autocomplete
                      
                      v-model="record.recordCategoryId"
                      label="Escolha uma categoria"
                      :class="{'ml-5':  record.type !== 'T'}"
                      item-text="name"
                      item-value="id"
                      append-icon="mdi-magnify"
                      :error-messages="recordRecordCategoryIdErrors"
                      :items="categoriasPorLancamentos()"
                      :disabled="modal.loading"
                      @blur="$v.record.recordCategoryId.$touch()"
                      clearable
                      outlined
                      filled
                      dense>
                      <template v-slot:item="data">
                        <v-list-item-icon style="margin-right: 8px;">
                          <v-icon :color="data.item.colour" v-if="!data.item.categoryId">mdi-arrow-right-bold-circle</v-icon>
                          <v-icon :color="'#FFF'" v-else></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-col>
                  <!-- :style="{ 'margin-left': record.type === 'T' ? '0px' : '15px' }" -->
              </v-row>

              <!-- Se for Recorrente mostra isso aqui -->
              <v-row no-gutters class="mb-3" v-if="record.jsonFlags !== false" >
                <v-col class=" pl-3 pt-3 pb-3 pr-3" style="background: #fff7dd">
                  <h4 class="mb-3 mt-2" style="color: " > Este lançamento se repete em outras datas. </h4>
                    <v-checkbox class="mt-0 pt-0 checkbox" v-model="record.typeUpdated" :label="`Atualizar apenas este lançamento`" value="now" ></v-checkbox>
                    <v-checkbox class="mt-0 pt-0 checkbox" v-model="record.typeUpdated" :label="`Atualizar este e os próximos`" value="nextAll" ></v-checkbox>
                    <v-checkbox class="mb-0 mt-0 pt-0 pb-0 checkbox" v-model="record.typeUpdated" :label="`Atualizar todos os lançamentos`" value="all" ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-12 d-flex text-center justify-content-center pb-0 pt-0">
                  <button
                    class="optionsButton"
                    :disabled="modal.loading"
                    @click="modal.showOptions = !modal.showOptions">
                    {{`${modal.showOptions ? '- Esconder' : '+ Mostrar'} opções`}}
                  </button>
                </v-col>
              </v-row>

              
                <v-row  v-if="modal.showOptions">
                  <v-col>
                    <v-row no-gutters class="limit-row">
                      <v-combobox
                        v-model="selectedTags"
                        :items="tagList"
                        :disabled="modal.loading"
                        item-value="id"
                        item-text="name"
                        label="Tags"
                        multiple
                        outlined
                        dense
                        chips
                      />
                    </v-row>

                    <v-row v-if="record.type !== 'T'">
                      <v-col class="pb-0 pt-0 mr-0" cols="12" sm="6" md="6">
                        <v-autocomplete
                        
                          v-model="record.costCenterId"
                          label="Centro(s) de custo(s)"
                          item-text="name"
                          item-value="id"
                          :items="costCenterList"
                          :disabled="modal.loading"
                          clearable
                          outlined
                          filled
                          dense>
                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col class="pb-0 pt-0 ml-0">
                        <v-autocomplete
                          v-model="record.contactId"
                          :label="record.type === 'D' ? 'Fornecedor' : 'Cliente'"
                          item-text="name"
                          item-value="id"
                          :items="record.type === 'D' ? contactList : customerContactList"
                          :disabled="modal.loading"
                          clearable
                          outlined
                          filled
                          dense
                          :autofocus="modal.recibo"
                          :error-messages="contactIdErrors"
                        >

                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>

                    </v-row>

                    <v-row v-if="record.type !== 'T'" no-gutters class="files d-flex flex-nowrap">
                      <v-file-input
                      class="mb-2 files-icon"
                        accept="image/*"
                        v-model="record.attachments"
                        :disabled="modal.loading"
                        hide-input
                        counter
                        outlined
                        clearable
                        multiple
                        filled
                        dense
                        >
                      </v-file-input>
                      <div class="files-add">
                        <div class="files-add-title"> Adicionar arquivos </div>
                          <template v-for="(val, index) in selectedAttachments" >
                              <v-chip :key="index"
                                v-if="selectedAttachments[index] ? selectedAttachments[index].name : ''"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                                @click="onDownloadFile($event, index)">
                                {{ selectedAttachments[index] ? selectedAttachments[index].name : '' }}

                                <v-icon
                                  v-if="selectedAttachments[index] ? selectedAttachments[index].name_s3 : 0"
                                  style="margin-left: 15px;">
                                  mdi-cloud-download-outline
                                </v-icon>

                                <v-icon
                                  style="margin-left: 15px;"
                                  @click="onDeleteFile($event, index)">
                                  mdi-close
                                </v-icon>
                              </v-chip>
                          </template>
                      </div>
                    </v-row>

                    <v-row no-gutters>
                      <v-textarea
                          label="Informações adicionais"
                          v-model="record.memo"
                          :disabled="modal.loading"
                          :aria-multiline="true"
                          rows="2"
                          outlined>
                        </v-textarea>
                    </v-row>
                  </v-col>
                </v-row>

                                    <!-- Repeticao -->
                    <v-row v-if="record.type !== 'T' && record.jsonFlags === false">
                      <v-col class="pb-0 pt-0">
                        <h4 > Repetir </h4>
                          <v-checkbox class="mt-0 pt-0 checkbox" v-model="parcelamento.type" :label="`Receita ou despesa fixa`" value="1" ></v-checkbox>
                          <v-checkbox class="mb-0 mt-0 pt-0 pb-0 checkbox" v-model="parcelamento.type" :label="`Receita ou despesa parcelada`" value="2" ></v-checkbox>
                      </v-col>
                    </v-row>

                    <v-row v-if="record.jsonFlags === false">
                      <!-- Parcelamento FIXO -->
                      <v-col class="col-6 pb-0 pt-0" v-if="parcelamento.type === '1'">
                        <v-autocomplete 
                          v-model="parcelamento.fixo"
                          :items="parcelamentoFixoSelect"
                          return-object
                          :label="'Escolha tipo fixo'"
                        />
                      </v-col>

                      <v-col class="col-6 pt-0" v-if="parcelamento.type === '1'">
                        <v-autocomplete 
                          v-model="parcelamento.qtd"
                          :items="selectQtd"
                          :label="'Qtd. repetições'"
                        />
                      </v-col>
                      
                      <!-- Parcelamento Parcelado -->
                      <v-col class="col-6 pb-0 pt-0" v-if="parcelamento.type === '2'">
                        <v-autocomplete 
                          v-model="parcelamento.parcelado"
                          :items="parcelamentoFixoSelect"
                          return-object
                          :label="'Escolha opção de parcelamento'"
                        />
                      </v-col>

                      <v-col class="col-6 pt-0" v-if="parcelamento.type === '2'">
                        <v-autocomplete 
                          v-model="parcelamento.qtd"
                          :items="selectQtd"
                          :label="'Qtd. repetições'"
                        />
                      </v-col>

                    </v-row>

              <OrganizzeModalFooter :loading="modal.loading" @onConfirm="enviar()"/>
          </v-col>

        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import Events from '@/core/service/events'
import { required, requiredIf, minValue } from 'vuelidate/lib/validators'
import validation from '../validation'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import { recordsTypesEnum } from '@/utils/tempUtils'
// eslint-disable-next-line
import { filter, map, flatten, some, cloneDeep, groupBy, flattenDeep, range, includes, size, uniqBy } from 'lodash'
import variables from '@/assets/styles/helpers/_variables.scss'

const namespaceStore = 'organizzeFinanceiroLancamentos'

export default {
  name: 'OrganizzeFinanceiroLancamentosModalCadastro',
  props: {},
  directives: {},
  components: {
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header'),
    OrganizzeReactionMenu: () => import('@/views/components/organizze/reactionMenu'),
    OrganizzeModalFooter: () => import('@/views/components/organizze/modal/footer')
  },
  data: () => ({
    recorrenceDescription: '',
    modal: {
      toggle: false,
      loading: false,
      isEdit: false,
      showOptions: false,
      hasTag: false,
      recibo: false,
      isCopy: false
    },
    recordType: {
      id: null,
      name: 'Despesa',
      type: 'D'
    },
    parcelamentoFixoSelect: [],
    selectQtd: range(2, 38),
    parcelamento: {
      type: null,
      fixo: {},
      parcelado: {},
      qtd: null
    },
    record: {
      id: null,
      accountId: null,
      accountIdTo: null,
      recordCategoryId: null,
      contactId: null,
      value: 0.0,
      status: 'P',
      type: null,
      description: '',
      memo: '',
      costCenterId: null,
      dtEmission: moment().format('YYYY-MM-DD'),
      dtDue: moment().format('YYYY-MM-DD'),
      dtPaid: moment().format('YYYY-MM-DD'),
      interestValue: 0.0,
      fineValue: 0.0,
      tags: [],
      attachments: [],
      transaction: null,
      attachmentsToDelete: [],
      jsonFlags: false
    },
    dtEmissionLabel: moment().format('DD/MM/YYYY'),
    dtDueLabel: moment().format('DD/MM/YYYY'),
    dtPaidLabel: moment().format('DD/MM/YYYY'),
    wasPaid: false,
    selectedTags: [],
    selectedAttachments: [],
    backupAttachments: []
  }),
  created () {},
  mounted () {
    const self = this
    self.parcelamentoFixoSelect = self.generateLabelsFixo()
    Events.$on('modalCadastro::open', val => {
      self.modal.toggle = true
      self.parcelamento.type = null
      self.parcelamento.fixo = {}
      self.parcelamento.parcelado = {}

      self.recordType = {
        id: val.recordType.id,
        name: val.recordType.name,
        type: val.recordType.type
      }
      self.record.type = self.recordType.type

      if (self.record.type === 'T') self.record.recordCategoryId = self.otherCategoryList.find(item => item.name === 'Transferências Manuais').id
    })

    Events.$on('modalCadastro::edit', (val, name) => {
      self.modal.toggle = true
      self.modal.isEdit = true
      self.parcelamento.type = null
      self.parcelamento.fixo = {}
      self.parcelamento.parcelado = {}

      if (name === 'recibo') {
        self.modal.recibo = true
        self.modal.showOptions = true
      }

      self.recordType = filter(recordsTypesEnum(), item => item.type === val.record.type)[0]

    /* trata descricao para nunca alterar (atual/qtd) na descricao */
      self.recorrenceDescription = ''
      let descricao = val.record.jsonFlags && val.record.jsonFlags.parentId ? String(val.record.description).split('(') : val.record.description
      descricao = typeof descricao === 'string' ? descricao : String(descricao[0]).trim()
      if (val.record.jsonFlags && val.record.jsonFlags.parentId) {
        self.recorrenceDescription = val.record.description.split('(')
        self.recorrenceDescription = ` (${self.recorrenceDescription[self.recorrenceDescription.length - 1]}`
      }
      self.record = {
        id: val.record.id,
        accountId: val.record.Accounts.id,
        accountIdTo: val.record.accountIdTo,
        accountIdFrom: val.record.accountIdFrom,
        recordCategoryId: (val.record.RecordCategories || {}).id,
        contactId: (val.record.Contacts || {}).id,
        value: Math.abs(parseFloat(val.record.value).toFixed(2)),
        status: val.record.status,
        type: val.record.type,
        description: descricao,
        memo: val.record.memo,
        costCenterId: (val.record.CostCenters || {}).id,
        dtEmission: moment(val.record.dtEmission).format('YYYY-MM-DD'),
        dtDue: moment(val.record.dtDue).format('YYYY-MM-DD'),
        dtPaid: moment(val.record.dtPaid || moment()).format('YYYY-MM-DD'),
        interestValue: val.record.interestValue,
        fineValue: val.record.fineValue,
        jsonFlags: val.record.jsonFlags,
        tags: (() => flatten(map(val.record.RecordTags, recordTag => map(recordTag.tag, tag => tag))))(),
        transaction: val.record.transaction,
        attachmentsToDelete: [],
        attachments: val.record.Attachments
      },
      self.dtEmissionLabel = moment(val.record.dtEmission).format('DD/MM/YYYY'),
      self.dtDueLabel = moment(val.record.dtDue).format('DD/MM/YYYY'),
      self.dtPaidLabel = moment(val.record.dtPaid || moment()).format('DD/MM/YYYY'),
      self.wasPaid = val.record.status === 'E'
      self.selectedTags = self.record.tags
      self.selectedAttachments = val.record.Attachments
      self.backupAttachments = val.record.Attachments
    })
  },
  computed: {
    ...mapGetters(namespaceStore, [
      'accountList',
      'tagList',
      'costCenterList',
      'expenditureCategoryList',
      'incomeCategoryList',
      'otherCategoryList',
      'contactList',
      'customerContactList'
    ]),
    ...validation,
    variables: () => variables
  },
  watch: {
    'record.dtEmission' () {
      const self = this
      self.dtEmissionLabel = moment(self.record.dtEmission).format('DD/MM/YYYY')
      if (self.record.dtDue < self.record.dtEmission) self.record.dtDue = self.record.dtEmission
    },

    'record.dtDue' () {
      const self = this
      self.dtDueLabel = moment(self.record.dtDue).format('DD/MM/YYYY')
    },

    'record.dtPaid' () {
      const self = this
      self.dtPaidLabel = moment(self.record.dtPaid).format('DD/MM/YYYY')
    },

    'wasPaid' () {
      const self = this
      if (self.wasPaid) self.record.status = 'E'
      else self.record.status = 'P'
    },
    async 'record.attachments'  (val) {
      const item = await this.loadFiles(val)
      if (item === false) return
      console.log(item)
      item['parentId'] = this.record.id
      this.selectedAttachments.push(item)


    },
    'record.attachmentsasds' (val) {
      const self = this
      if (self.record.attachments.length > 0) {
        const toAdd = filter(val, item => !some(self.record.attachments, attachment => item.name === attachment.name))

        if (toAdd.length > 0) {
          // self.selectedAttachments = self.record.attachments.concat(toAdd)
          // self.record.attachments = self.record.attachments.concat(toAdd)
          self.selectedAttachments.push(toAdd[0])
          self.record.attachments.push(toAdd[0])
          return
        }
      }

      if (val.length === 0 || filter(val, item => !item.type.match('image.*')).length !== 0) return
      console.log('1',self.selectedAttachments)
      console.log('2', self.record.attachments)
      
      for (const item of val) {
        if (!item.name_s3) {
          const reader = new FileReader()
          reader.onload = (e) => self.record.attachments.push({
            parentId: self.record.id,
            name: item.name,
            name_s3: item.name_s3,
            type: item.type,
            data: e.target.result,
            folder_s3: '/records'
          })

        if (item.size) reader.readAsDataURL(item)

        } else {
          self.record.attachments.push(item)
        }
      }
    },

    'record.value' () {
      const self = this
      self.record.value = Math.abs(self.record.value)
    },

    'record.interestValue' () {
      const self = this
      self.record.interestValue = Math.abs(self.record.interestValue)
    },

    'record.fineValue' () {
      const self = this
      self.record.fineValue = Math.abs(self.record.fineValue)
    }
  },
  methods: {
    ...mapActions(namespaceStore, ['submit', 'downloadImage', 'getRecordId']),
    async loadFiles (file) {
      if (!size(file) || size(file) > 1) return false
      return new Promise((resolve, reject) => {
        const fr = new FileReader()
        fr.onerror = reject;
        fr.onload = (img) => {
          console.log('img', img)
            resolve({
              parentId: this.record.id,
              name: file[0]['name'],
              type: file[0]['type'],
              data: fr.result,
              folder_s3: '/records'
            })
        }
        fr.readAsDataURL(file[0])
      })
    },
    generateLabelsFixo () {
      return [
        { text: 'Anual', key: 'years' },
        { text: 'Semestral', key: 'month', d: 6 },
        { text: 'Trimestral', key: 'quarter', d: 3 },
        { text: 'Bimestral', key:'month', d: 2 },
        { text: 'Mensal', key: 'month' },
        { text: 'Quinzenais', key: 'days', d: 15 },
        { text: 'Semanal', key: 'weeks' },
        { text: 'Diárias', key: 'days' }
      ]
    },
    onReact (val) {
      const self = this
      self.wasPaid = !val
    },

    enviar () {
      const self = this
      
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      self.modal.loading = true

      if (!self.wasPaid) self.record.dtPaid = null

      self.record.tags = map(self.selectedTags, selectedTag => {
        if (typeof(selectedTag) === 'string') return { name: selectedTag }
        else return selectedTag
      })

      // if (self.record.type === 'T') self.record.description = 'Transferência'
       if(self.record.typeUpdated === 'now') {
        self.record.description = `${self.record.description} ${self.recorrenceDescription}`
      }
      if (self.record.type === 'T') self.record.accountId = self.record.accountIdFrom

      if (self.modal.isEdit && self.record.jsonFlags && !self.record.typeUpdated){
        self.modal.loading = false
        return errorSnackbar('Selecione o tipo de atualizacão!')
      }
      // if (self.record.type === 'T') self.record.recordCategoryId = self.otherCategoryList.find(item => item.name === 'TRANSFERÊNCIA').id
      self.record.attachments = self.selectedAttachments
      const data = {
        record: self.record,
        isEdit: self.modal.isEdit,
        isRepeat: self.parcelamento.type ? self.parcelamento : false,
        isCopy: false, // duplica lancamento existente
        isTransfer: self.record.type === 'T',
        typeUpdated: self.record.typeUpdated || false
      }
      

      self.submit(data).then(() => {
        if (!self.modal.isEdit) {
          successSnackbar('Lançamento adicionado com sucesso.')
          self.modal.loading = false
          Events.$emit('index::getRecords')
          self.closeModal()
        } else {
          successSnackbar('Lançamento editado com sucesso.')

          if (self.modal.recibo) {
            self.getRecordId({ _addlFilter: { 'Records:id_=': parseInt(data.record.id) } }).then(record => {
              self.$emit('onEmitirRecibo', record[0])
              self.modal.loading = false
              self.closeModal()
            })
          } else {
            Events.$emit('OrganizzeFilter::buscar')
            self.closeModal()
            self.modal.loading = false
          }
        }
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
       }).finally(() => {
         self.modal.loading = false
        // self.modal.loading = false
      })
    },

    closeModal () {
      const self = this
      console.log('fechou')
      self.modal = {
        toggle: false,
        loading: false,
        isEdit: false,
        showOptions: false,
        hasTag: false
      }

      self.recordType = {
        id: null,
        name: '',
        type: null
      }

      self.record = {
        id: null,
        accountId: null,
        accountIdFrom: null,
        accountIdTo: null,
        recordCategoryId: null,
        contactId: null,
        value: 0.0,
        status: 'P',
        type: null,
        description: '',
        memo: '',
        costCenterId: null,
        dtEmission: moment().format('YYYY-MM-DD'),
        dtDue: moment().format('YYYY-MM-DD'),
        dtPaid: moment().format('YYYY-MM-DD'),
        interestValue: 0.0,
        fineValue: 0.0,
        tags: [],
        attachments: [],
        transaction: null,
        attachmentsToDelete: [],
        jsonFlags: false
      }

      self.dtEmissionLabel = moment().format('DD/MM/YYYY'),
      self.dtDueLabel = moment().format('DD/MM/YYYY'),
      self.dtPaidLabel = moment().format('DD/MM/YYYY'),
      self.wasPaid = false
      self.selectedTags = []
      self.selectedAttachments = []
      self.backupAttachments = []
    },

    expenditureCategoryListGrouped (listCategories) {
      const categories = listCategories
      const categoriesParent = categories.filter(item => !item.categoryId)
      const categoriesParentChildren = categoriesParent.flatMap(item => {
        const categoriesChildren = categories.filter(sitem => sitem.categoryId === item.id).flatMap(sitem => ({
              id: sitem.id,
              name: sitem.name,
              categoryId: sitem.categoryId,
              colour: sitem.colour,
              disabled: sitem.jsonFlags && sitem.jsonFlags.InportOnly 
            }))
        return [ { id: item.id, name: item.name, categoryId: item.categoryId, colour: item.colour, disabled: true }, ...categoriesChildren ]
      })
      return categoriesParentChildren
    },

    categoriasPorLancamentos () {
      const self = this
      switch (self.recordType.type) {
        case 'D': return self.expenditureCategoryListGrouped(self.expenditureCategoryList)
        case 'R': return self.expenditureCategoryListGrouped(self.incomeCategoryList)
        default: return self.expenditureCategoryListGrouped(self.otherCategoryList)
      }
    },

    isDiffValue () {
      const self = this
      return self.record.type === 'T' ? self.record.accountIdFrom !== self.record.accountIdTo : true
    },

    async onDownloadFile (event, index) {
      const self = this
      event.stopPropagation()
      self.downloadImage(self.selectedAttachments[index])
    },

    onDeleteFile (event, index) {
      const self = this
      event.stopPropagation()
      if (self.selectedAttachments[index].name_s3 !== undefined) self.record.attachmentsToDelete.push(self.selectedAttachments[index])

      self.selectedAttachments.splice(index, 1)
      self.record.attachments = self.selectedAttachments
    }
  },
  validations () {
    const self = this
    return {
      record: {
        description: { required: requiredIf(() => self.record.type !== 'T') },
        value: { required, minValue:  minValue(0.01) },
        accountId: { required: requiredIf(() => self.record.type !== 'T') },
        accountIdFrom: { required: requiredIf(() => self.record.type === 'T'), isSameValue: self.isDiffValue},
        accountIdTo: { required: requiredIf(() => self.record.type === 'T'), isSameValue: self.isDiffValue},
        recordCategoryId: { required: requiredIf(() => self.record.type !== 'T') },
        contactId: { required: requiredIf(() => self.modal.recibo) }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }

  .optionsButton {
    color: white;
    padding: 6px;
    background-color: $colorAccent;
    border-radius: 16px;
    margin: 0 auto;
  }
  div.limit-row {
    max-height: 55px;
    @media(max-width: 768px) {
      max-height: initial;
    }
    .v-input::v-deep .v-input__control .v-input__slot {
        min-height: 45px;
    }
    .v-input::v-deep .v-input__append-inner{
      margin-top: 10px
    }
    .v-input::v-deep .v-input__append-outer{
      margin-top: 12px
    }
    .checkbox::v-deep .v-input__control .v-input__slot{
      margin-bottom: 0;
    }
  }
.checkbox::v-deep .v-input__control .v-input__slot{
  margin-bottom: 0;
}
.checkbox{
  max-height: 25px;
}

.files {
  margin-top: 5px;
  position: relative;
}

.files-icon {
  position: absolute;
  z-index: 10;
}

.files-add {
  position: relative;
  background: #0000000f;
  min-height: 40px;
  padding: 5px;
  width: 100%;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  transition: all 0.3;
  &:hover {
    border: 1px solid #242424;
  }

  &-title {
    position: absolute;
    left: 50%;
    top: -80%;
    z-index: 0;
    transform: translate(-50%, 50%);
    font-size: 14px;
  }
  span {
    margin: 5px;
  }
}
</style>